import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { JobWrapperComponent } from '../../../shared/components/job-wrapper/job-wrapper.component';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'ta-cost-wrapper',
  standalone: true,
  template: `
    <ta-job-wrapper [visible]="true" dialogCssClass="tw-h-full lg:tw-h-[44.25rem] tw-w-full lg:tw-w-[40rem]">
      <router-outlet></router-outlet>
    </ta-job-wrapper>
  `,
  imports: [RouterOutlet, DialogComponent, JobWrapperComponent]
})
export class CostWrapperComponent {}
