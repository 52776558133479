import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/core/app.config';
import { AppComponent } from './app/app.component';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment as Config } from './environments/environment';
import { enableMapSet } from 'immer';
// Import lingua italiana libreria bryntum
import "@bryntum/schedulerpro-thin/lib/localization/It.js";
import "@bryntum/calendar-thin/lib/localization/It.js";

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);

// Abilito la gestione delle mappe e dei set in immer
enableMapSet();

// Registering Syncfusion license key
registerLicense(Config.sfKey);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
