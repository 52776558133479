import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as Config } from 'src/environments/environment';
import { PriorityDTO, PriorityType } from '../../ticket/ticket.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faBell, faDotCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@jsverse/transloco';
import { RxjsUtils } from '../../../shared/utility/rxjs-utils';

@Injectable({
  providedIn: 'root'
})
export class PriorityService {
  private readonly translocoService = inject(TranslocoService);
  private readonly http = inject(HttpClient);
  private readonly rxjsUtils = inject(RxjsUtils);
  private readonly baseUrlPath = `${Config.apiBaseUrl}/priority`;

  public static prioritiesDp: { value: PriorityType, text: string, ico: IconProp, icoCss: string }[];

  constructor () {
    this.translocoService.selectTranslate<string[]>(['urgent', 'important', 'normal', 'lower'])
      .subscribe(([urgent, important, normal, lower]) => {
        PriorityService.prioritiesDp = [
          { value: 'Urgent', text: urgent, ico: faBell, icoCss: 'tw-text-priority-urgent' },
          { value: 'Important', text: important, ico: faExclamation, icoCss: 'tw-text-priority-important' },
          { value: 'Normal', text: normal, ico: faDotCircle, icoCss: 'tw-text-priority-normal' },
          { value: 'Lower', text: lower, ico: faArrowDown, icoCss: ' tw-text-priority-lower' }
        ];
      });
  }

  public getAllPriorities (): Observable<PriorityDTO[]> {
    return this.http.get<PriorityDTO[]>(`${this.baseUrlPath}`)
      .pipe(this.rxjsUtils.simpleCatchErrorWithLog<PriorityDTO[]>([]));
  }
}
