import { LanguageCodes } from '../language.model';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import axios from 'axios';

const loadedLocales: LanguageCodes[] = [];

// Funzione per caricare in modalità lazy i file di traduzione e impostare la lingua dei componenti syncfusion
export const loadAndSetCulture = async (locale: LanguageCodes): Promise<void> => {
  if (!loadedLocales.includes(locale)) {
    loadedLocales.push(locale);

    const files: string[] = ['ca-gregorian.json', 'numbers.json', 'timeZoneNames.json'];
    const loadCulture = async (prop: number, weekdata: Object[]): Promise<void> => {
      const response = await axios.get(`./assets/cldr/${locale}/${files[prop]}`);
      if (response.status === 200) {
        loadCldr(response.data, weekdata);
      }
    };

    const weekdataXHR = await axios.get('./assets/cldr/supplemental/weekData.json');
    if (weekdataXHR.status === 200) {
      for (let idx = 0; idx < files.length; idx++) {
        await loadCulture(idx, weekdataXHR.data);
      }
    }

    if (locale === 'it') {
      const xhrLocale = await axios.get(`./assets/i18n/syncfusion/${locale}.json`);
      if (xhrLocale.status === 200) {
        L10n.load(xhrLocale.data);
      }
    }
  }
  setCulture(locale);
};
