import { LanguageCodes } from './language.model';
import * as SyncfusionLang from './syncfusion/language';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/it';
import { LocaleHelper, LocaleManager } from "@bryntum/core-thin";
import { capitalize } from "../../shared/utility/utility";

registerLocaleData(it);
registerLocaleData(en);

export const setLanguage = (lang: LanguageCodes): void => {
  dayjs.locale(lang);
  dayjs.extend(updateLocale);
  dayjs.updateLocale(lang,{ weekStart: 1 });
  SyncfusionLang.loadAndSetCulture(lang);
  // Set lingua bryntum
  LocaleManager.locale = LocaleHelper.locales[capitalize(lang)];
};
